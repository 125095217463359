// pages/Test.tsx
import React from 'react';

import '../App.css'
import Button from '../components/Button/Button';



const Test: React.FC = () => {
  return (
    <div>
        <h1>Google Sheets Row Fetcher</h1>
        <Button buttonStyle='btn--outline' buttonSize='btn--large'>SIGN UP</Button>
    </div>
  );
};

export default Test;

