// src/hooks/useLocalStorageListener.ts
import { useEffect } from 'react';

const useLocalStorageListener = (key: string, callback: () => void) => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        callback();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, callback]);
};

export default useLocalStorageListener;
