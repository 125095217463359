// src/components/Game.tsx
import React from 'react';
import {  useAppContext } from '../context/AppContext';

import './Game.css'
import GameTable from '../components/GameTable/GameTable';

const Game: React.FC = () => {
  const { addgameProperty } = useAppContext();

  const handleAddProperty = () => {
    const newProperty = prompt("Enter new game property:");
    if (newProperty) {
      addgameProperty(newProperty);
    }
  };

  return (
    <div className='Game-container'>
      <h1>Game</h1>
      <button onClick={handleAddProperty}>Add Game Property</button>
      <GameTable/>
    </div>
  );
};

export default Game;
