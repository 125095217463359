// src/components/Game.tsx
import React, { useEffect,useState  } from 'react';
import { Property, useAppContext } from '../../context/AppContext';
import useLocalStorageListener from '../../hooks/useLocalStorageListener';

import './GameTable.css'

const GameTable: React.FC = () => {
  const { players, matches, gameProperty, updateMatch } = useAppContext();

  useEffect(() => {
    if (matches.length === 0 && players.length > 1) {
      const newMatches = [];
      for (let i = 0; i < players.length; i++) {
        for (let j = i + 1; j < players.length; j++) {
          const scores = gameProperty.reduce((acc, prop) => {
            acc[prop.id] = 0;
            return acc;
          }, {} as { [key: string]: number });

          newMatches.push({ player1: players[i], player2: players[j], player1_Scores: scores,player2_Scores:scores });
        }
      }
      localStorage.setItem('matches', JSON.stringify(newMatches));
    }
  }, [players, gameProperty, matches.length]);

  const handleInputChange = (index: number, property: Property, value: string, player: 1|2) => {
    
    const numericValue = Math.max(parseInt(value, 10),0);
    if (!isNaN(numericValue)) {
      updateMatch(index, property, numericValue, player);
    }
  };

  const [localMatches, setLocalMatches] = useState(matches);

  useLocalStorageListener('matches', () => {
    const updatedMatches = localStorage.getItem('matches');
    if (updatedMatches) {
      console.log`amount: ${matches.length}`
      setLocalMatches(JSON.parse(updatedMatches));
    }
  });

  return (
    <div className='table-container'>
      <table>
        <thead>
          <tr>
            <th>Player</th>
            {gameProperty.map((property, index) => (
              <React.Fragment key={index}>
                <th style={(property.multiplier===1?{paddingBottom:'23px'}:{})} >{property.name}<br></br>{property.multiplier>1?`Mult x${property.multiplier}`:' '}</th>
              </React.Fragment>
            ))}
            <th>Total Score</th>
          </tr>
        </thead>
        <tbody>
          {localMatches.map((match, index) => (
            <tr key={index}>
              <td> <div className='cell-top'> {match.player1.name} </div> <div className='cell-bottom'> {match.player2.name} </div> </td>
              {gameProperty.map((property, propIndex) => (
                <React.Fragment key={propIndex}>
                  <td>
                    <div className='cell-top'>
                      <input
                        type="number"
                        value={match.player1_Scores[property.id]}
                        onChange={(e) => handleInputChange(index, property, e.target.value, 1)}
                      />
                      <button onClick={() => handleInputChange(index, property, (match.player1_Scores[property.id.toString()]-1).toString(), 1)} className="btn-down"></button>
                      <button onClick={() => handleInputChange(index, property, (match.player1_Scores[property.id.toString()]+1).toString(), 1)} className="btn-up"></button>
                    </div>
                    <div className='cell-bottom'>
                      <input
                        type="number"
                        value={match.player2_Scores[property.id]}
                        onChange={(e) => handleInputChange(index, property, e.target.value, 2)}
                      />
                      <button onClick={() => handleInputChange(index, property, (match.player2_Scores[property.id.toString()]-1).toString(), 2)} className="btn-down"></button>
                      <button onClick={() => handleInputChange(index, property, (match.player2_Scores[property.id.toString()]+1).toString(), 2)} className="btn-up"></button>
                    </div>
                  </td>
                </React.Fragment>
              ))}
              <td>
                <div className='cell-top'> {gameProperty.reduce((total, current) => total + (match.player1_Scores[current.id.toString()]*(gameProperty.filter((property) => property.id === current.id)[0].multiplier)), 0)}</div>
                <div className='cell-bottom'> {gameProperty.reduce((total, current) => total + match.player2_Scores[current.id.toString()], 0)} </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GameTable;
