import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Test from './pages/Test';
import Navbar from './components/Navbar/Navbar';
import Players from './pages/Players';
import Game from './pages/Game';
import { AppProvider } from './context/AppContext';
import GameProperties from './components/GameProperties';

function App() {
  return (
    <AppProvider>
      <Router>
        <Navbar/>
        <div className="App">
          {/* example comment */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/test" element={<Test />} />
            <Route path="/players" element={<Players />} />
            <Route path="/game" element={<Game />} />
            <Route path="/game-properties" element={<GameProperties />} />
          </Routes>
        </div>
      </Router>
    </AppProvider>
    
  );
}

export default App;
