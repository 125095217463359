// import React from 'react';
// import './Button.css';
// import { Link } from 'react-router-dom';

// export function Button() {
//   return (
//     <Link to='sign-up'>
//       <button className='btn'>Sign Up</button>
//     </Link>
//   );
// }

import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

//const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

//const SIZES = ['btn--medium', 'btn--large'];

interface ButtonProps {
    children?:any;
    type?: any;
    onClick?: any;
    buttonStyle: 'btn--primary'| 'btn--outline'| 'btn--test';
    buttonSize: 'btn--medium'| 'btn--large';
}

const Button: React.FC<ButtonProps> = ({ children, type, onClick, buttonStyle, buttonSize }) => {

  return (
    <Link className='btn-mobile' to={''}>
      <button
        className={`btn ${buttonStyle} ${buttonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Link>
  );
};

export default Button