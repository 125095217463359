// src/components/PlayerList.tsx
import React from 'react';
import { useAppContext } from '../context/AppContext';

import './Editing.css'

const PlayerList: React.FC = () => {
  const { players, deletePlayer } = useAppContext();

  return (
    <ul className='playerList-container'>
      {players.map(player => (
        <li key={player.id}>
          {player.name}
          <button onClick={() => deletePlayer(player.id)}>Delete</button>
        </li>
      ))}
    </ul>
  );
};

export default PlayerList;
