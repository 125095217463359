// src/components/Players.tsx
import React from 'react';
import PlayerForm from '../components/PlayerForm';
import PlayerList from '../components/PlayerList';

import '../App.css'

const Players: React.FC = () => (
  <div className='page-container'>
    <h1>Players</h1>
    <PlayerForm />
    <PlayerList />
  </div>
);

export default Players;
