// src/components/PlayerForm.tsx
import React, { useState } from 'react';
import { useAppContext } from '../context/AppContext';

import './Editing.css'

const PlayerForm: React.FC = () => {
  const [name, setName] = useState('');
  const { addPlayer } = useAppContext();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim() === '') {
      return;
    }
    addPlayer(name);
    setName('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Player Name"
        required
      />
      <button type="submit">Add Player</button>
    </form>
  );
};

export default PlayerForm;
