// src/context/AppContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface Player {
  id: number;
  name: string;
}

export interface Property {
  id: number;
  name: string;
  multiplier: number;
}

interface Match {
  player1: Player;
  player2: Player;
  player1_Scores: { [key: string]: number };
  player2_Scores: { [key: string]: number };
}

interface AppContextType {
  players: Player[];
  matches: Match[];
  gameProperty: Property[];
  addPlayer: (name: string) => void;
  deletePlayer: (id: number) => void;
  addMatch: (player_1:Player, player_2:Player) => void;
  updateMatch: (index: number, property: Property, value: number, player: 1|2) => void;
  addgameProperty: (property: string) => void;
  deletegameProperty: (property: number) => void;
  updategameProperty: (oldProperty: Property, newName: string,newMult: number) => void;
  //updateGameProperty: (oldProperty: string, newProperty: string) => void;
  reorderGameProperties: (properties: Property[]) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [players, setPlayers] = useState<Player[]>(() => {
    const savedPlayers = localStorage.getItem('players');
    return savedPlayers ? JSON.parse(savedPlayers) : [];
  });

  const [matches, setMatches] = useState<Match[]>(() => {
    const savedMatches = localStorage.getItem('matches');
    return savedMatches ? JSON.parse(savedMatches) : [];
  });

  const [gameProperty, setgameProperty] = useState<Property[]>(() => {
    const savedProperties = localStorage.getItem('gameProperty');
    return savedProperties ? JSON.parse(savedProperties) : [{id:0,name:'Bombe',multiplier:5}, {id:1,name:'Trckshot',multiplier:2}, {id:3,name:'Prop3',multiplier:1}];
  });

  useEffect(() => {
    localStorage.setItem('players', JSON.stringify(players));
  }, [players]);

  useEffect(() => {
    localStorage.setItem('matches', JSON.stringify(matches));
  }, [matches]);

  useEffect(() => {
    localStorage.setItem('gameProperty', JSON.stringify(gameProperty));
  }, [gameProperty]);

  const addPlayer = (name: string) => {
    const newPlayer = { id: Date.now(), name };
    for (let i = 0; i < players.length; i++) {
      addMatch(players[i],newPlayer)      
    }
    setPlayers((prevPlayers) => [...prevPlayers, newPlayer]);
  };

  const deletePlayer = (id: number) => {
    setPlayers((prevPlayers) => prevPlayers.filter(player => player.id !== id));
    deleteMatch(id)
  };

  const updateMatch = (index: number, property: Property, value: number, player: 1|2) => {
    setMatches((prevMatches) => prevMatches.map((match, i) =>
      i === index ? { ...match, [`player${player}_Scores`]: { ...match[`player${player}_Scores`], [property.id]: value }} : match
    ));
    localStorage.setItem('matches', JSON.stringify(matches));
    return matches;
  };

  const deleteMatch = (playerId: number) => {
    setMatches((prevMatches) => prevMatches.filter(p => p.player1.id !== playerId && p.player2.id !== playerId));
  };

  const addMatch = (player_1:Player, player_2:Player) => {
      console.log(player_1,player_2);
      const emptyDict: {[key: string]: number} = {}
      gameProperty.forEach((prop:Property) => {
        emptyDict[prop.id.toString()] = 0
      })
      setMatches((prevMatches) => [...prevMatches, {player1:player_1, player2:player_2, player1_Scores: emptyDict, player2_Scores: emptyDict }]);
  }
  
  const addgameProperty = (property: string) => {
    const newId = Date.now()
    setgameProperty((prevProperties) => [...prevProperties, {id: newId,name:property,multiplier:1}]);
    setMatches((prevMatches) => prevMatches.map(match => ({
      ...match,
      player1_Scores: {...match.player1_Scores, [newId.toString()]:0},
      player2_Scores: {...match.player1_Scores, [newId.toString()]:0}
    })));
  };

  const deletegameProperty = (property: number) => {
    //const property = property.toString();
    setgameProperty((prevProperties) => prevProperties.filter(p => p.id !== property));
    setMatches((prevMatches) => prevMatches.map(match => {
      const { [property.toString()]: _, ...rest1 } = match.player1_Scores;
      const { [property.toString()]: __, ...rest2 } = match.player2_Scores;
      return { ...match, player1_Scores: rest1, player2_Scores: rest2};
    }));
  };

  const updategameProperty = (oldProperty: Property, newName: string, newMult:number) => {
    setgameProperty((prevProperties) => prevProperties.map(p => p === oldProperty ? {id:oldProperty.id,name:newName,multiplier:newMult} : p));
  };

  const reorderGameProperties = (properties: Property[]) => {
    setgameProperty(properties);
  };

  return (
    <AppContext.Provider value={{
      players, matches,gameProperty, addPlayer, deletePlayer, updateMatch, addMatch, updategameProperty, reorderGameProperties,addgameProperty,deletegameProperty
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
