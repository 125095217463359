// src/components/GameProperties.tsx
import React, { useState } from 'react';
import { Property,useAppContext } from '../context/AppContext';

import './Editing.css'

const GameProperties: React.FC = () => {
  const { gameProperty, addgameProperty, deletegameProperty, updategameProperty, reorderGameProperties } = useAppContext();
  const [newProperty, setNewProperty] = useState('');
  const [editingProperty, setEditingProperty] = useState<Property | null>(null);
  const [editedPropertyName, setEditedPropertyName] = useState('');
  const [editedPropertyMult, setEditedPropertyMult] = useState(1);

  const handleAddProperty = () => {
    if (newProperty.trim() !== '') {
      addgameProperty(newProperty.trim());
      setNewProperty('');
    }
  };

  const handleEditProperty = (property: Property) => {
    setEditingProperty(property);
    setEditedPropertyName(property.name);
    setEditedPropertyMult(property.multiplier);
  };

  const handleUpdateProperty = () => {
    if (editingProperty && editedPropertyName.trim() !== '' && editedPropertyMult) {
      console.log(editingProperty.id,editingProperty.name)
      console.log(editedPropertyName.trim())
      updategameProperty(editingProperty, editedPropertyName.trim(),editedPropertyMult);
      //updateGameProperty(editingProperty, editedPropertyName.trim());
      setEditingProperty(null);
      setEditedPropertyName('');
      setEditedPropertyMult(1);
    }
  };

  const handleDeleteProperty = (property: Property) => {
    deletegameProperty(property.id);
  };

  const handleReorder = (index: number, direction: 'up' | 'down') => {
    const newProperties = [...gameProperty];
    const [movedProperty] = newProperties.splice(index, 1);
    newProperties.splice(direction === 'up' ? index - 1 : index + 1, 0, movedProperty);
    reorderGameProperties(newProperties);
  };

  return (
    <div>
      <h1>Game Properties</h1>
      <input
        type="text"
        value={newProperty}
        onChange={(e) => setNewProperty(e.target.value)}
        placeholder="New Property"
      />
      <button onClick={handleAddProperty}>Add Property</button>
      <ul className='property-container'>
        {gameProperty.map((property, index) => (
          <li key={property.name}>
            {editingProperty === property ? (
              <div>
                <div className='left-container'>
                  <input
                    type="text"
                    value={editedPropertyName}
                    onChange={(e) => setEditedPropertyName(e.target.value)}
                  />
                  <input
                    type="number"
                    value={editedPropertyMult}
                    onChange={(e) => setEditedPropertyMult(Math.max(Number(e.target.value),1))}
                  />
                </div>
                <div className='right-container'>
                  <button onClick={handleUpdateProperty}>Save</button>
                  <button onClick={() => setEditingProperty(null)}>Cancel</button>
                </div>
              </div>
            ) : (
              <div>
                <div className='left-container'>{property.id} {property.name} {property.multiplier}</div>
                <div className='right-container'>
                  <button onClick={() => handleEditProperty(property)}>Edit</button>
                  <button onClick={() => handleDeleteProperty(property)}>Delete</button>
                  {index > 0 && <button onClick={() => handleReorder(index, 'up')}>Up</button>}
                  {index < gameProperty.length - 1 && <button onClick={() => handleReorder(index, 'down')}>Down</button>}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GameProperties;
