import './Navbar.css'; // Import the CSS file for the nav styles
import { Link } from 'react-router-dom';

import logo from '../../images/beerpong.jpg';
import './Navbar.css'

const Navbar: React.FC = () => {

  return (
    <nav>
        <Link to="/" className="site-title">
            <img className='logo' src={logo} alt="beerpong logo"/>
        </Link>
        <ul>
            <li>
            <Link to="/">Home</Link>
            </li>
            <li>
            <Link to="/game">Game</Link>
            </li>
            <li>
            <Link to="/rules">Rules</Link>
            </li>
            <li><Link to="/players">Players</Link></li>
            <li><Link to="/game-properties">Game Properties</Link></li>
        </ul>
    </nav>
  );
};

export default Navbar;
